@font-face {
  font-family: "Caveat";
  src: url("../assets/fonts/Caveat.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Museo-Sans";
  src: url("../assets/fonts/MuseoSans_100.otf") format("opentype");
  font-weight: 100;
  font-display: swap;
}
@font-face {
  font-family: "Museo-Sans";
  src: url("../assets/fonts/MuseoSans_300.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Museo-Sans";
  src: url("../assets/fonts/MuseoSans_500.otf") format("opentype");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Museo-Sans";
  src: url("../assets/fonts/MuseoSans_700.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Museo-Sans", sans-serif;
}

body {
  overflow-x: hidden;
  width: 100%;
}

* {
  box-sizing: border-box;

  /*
  == Blue Current:
  */
  --colors-BLUE: #0098db;
  --colors-LIGHTBLUE: #e4f3fa;
  --colors-GREY: #37424a;
  --colors-BLACK: #121212;
  --colors-YELOOW: #fee000;
  --colors-ORANGE: #ff9932;
  --colors-GREEN: #00ca8b;
  --colors-WHITE: #ffffff;
  --colors-PURPLE: #ff3699;
  --colors-GRADIENT: #e5f4fb;
  /*
  == Design:
  */
  --colors-LIGHTGREY: #fbfbfb;
  --colors-MEDIUMGREY: #cccccc;
  --colors-LIGHTWHITE: #f5f5f5;
  --colors-MEDIUMWHITE: #eeeeee;
  --colors-DARKWHITE: #dfdfdf;
  /*
  == Custom:
  */
  --colors-RED: #ef6b6b;

  /*
  == Breakpoints:
  */
  --breakpoints-xsmax: "575px";
  --breakpoints-smmin: "576px";
  --breakpoints-smmax: "767px";
  --breakpoints-mdmin: "768px";
  --breakpoints-mdmax: "991px";
  --breakpoints-lgmin: "992px";
  --breakpoints-lgmax: "1199px";
  --breakpoints-xlmin: "1200px";
  --breakpoints-xlmax: "1399px";
  --breakpoints-xxlmin: "1400px";
  --breakpoints-xxlmax: "1799px";
  --breakpoints-xxxlmin: "1800px";
}

h1,
h2,
h3,
h4,
h5,
p,
a,
textarea,
address {
  margin: 0;
  font-family: "Museo-Sans", sans-serif;
  font-style: normal;
}

p {
  line-height: 21px;
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

button {
  -webkit-tap-highlight-color: transparent;
}

.PortableText p {
  padding-top: 12px;
}
.PortableText p:first-child {
  padding-top: 0px;
}

.grecaptcha-badge {
  visibility: hidden;
}
