a:hover {
  cursor: pointer !important;
}

.Hover:hover {
  cursor: pointer!important;
}

.Hover:hover .ContextMenu:hover {
  cursor: context-menu!important;
}

.Link a {
  text-decoration: underline;
}

.Disabled:hover {
  cursor: not-allowed !important;
}

.DisableSelection,
.DisableSelection > * {
  user-select: none!important;
  -ms-user-select: none!important;
  -moz-user-select: none!important;
  -khtml-user-select: none!important;
  -webkit-user-select: none!important;
  -webkit-touch-callout: none!important;
}

/* Blurred Background workaround */
.Blur {
  background: rgba(255, 255, 255, 0.993);
}

@supports (backdrop-filter: blur(30px)) {
  .Blur {
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
  }
}

/* HeaderLink hover */
.HeaderLink::after {
  transition: all 0.3s ease-in-out;
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  height: 2px;
  bottom: 0px;
  content: "";
  background-color: var(--colors-BLUE);
}

.HeaderLink:hover::after {
  width: 100%;
}

/* Loader Spinner */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
